















































































































































import Vue from 'vue'
import Component from 'vue-class-component'
import { vxm } from '@/store'
import getForesightMinuteOptions from '@/utils/bookingForesightOptions'

@Component({})
export default class Edit extends Vue {
  private data = {}
  private types = []
  private snapToOptions = []
  private foresightMinutesOptions = []
  private timeAlignmentModes = [
    { id: 'None', name: 'None (max utilization but calendar may become quite fragmented)' },
    { id: 'ByScheduleAcrossPeriod', name: 'Unified (same time slots every day)' },
    { id: 'ByScheduleAcrossDay', name: 'Per day (different days may have different slots)' },
  ]

  private save() {
    if (this.isNew) {
      this.$axios
        .post(this.url, this.data)
        .then((response) => {
          this.data = response.data.data
          this.$router.back()
        })
        .catch((err) => {
          vxm.alert.onAxiosError(err)
        })
    } else {
      this.$axios
        .put(this.url, this.data)
        .then((response) => {
          this.data = response.data.data
          this.$router.back()
        })
        .catch((err) => {
          vxm.alert.onAxiosError(err)
        })
    }
  }

  private cancel() {
    this.$router.back()
  }

  private get isAdmin(): boolean {
    if (!this.data) {
      return false
    }
    const data = this.data as Record<string, unknown>
    const type = data.type as string
    return type === 'AdminDefault'
  }

  public mounted(): void {
    this.types = [
      { id: 'Other', name: this.$t('c:booking-portal-name:Other') },
      { id: 'CustomerDefault', name: this.$t('c:booking-portal-name:CustomerDefault') },
      { id: 'AdminDefault', name: this.$t('c:booking-portal-name:AdminDefault') },
    ]
    if (this.isNew) {
      this.data = {}
    } else {
      this.$axios
        .get(this.url)
        .then((response) => {
          this.data = response.data.data
        })
        .catch((err) => {
          vxm.alert.onAxiosError(err)
        })
    }
    this.$axios
      .get('/v4/site/calendars/any/portals/admin/snap-to-options')
      .then((response) => {
        this.snapToOptions = response.data.data
      })
      .catch((err) => {
        vxm.alert.onAxiosError(err, 'Failed to load snap-to options')
      })
    this.foresightMinutesOptions = getForesightMinuteOptions((t: string) => {
      return this.$t(t)
    })
  }

  private get isNew() {
    return this.$route.meta.isNew
  }

  private get url() {
    const url = '/v4/site/calendars/' + this.$route.params.calendarId + '/portals'
    return this.isNew ? url : url + '/' + this.$route.params.id
  }

  private getSnapToHint(snapTo: string) {
    if (!snapTo) {
      return ''
    }
    for (let i = 0; i < this.snapToOptions.length; i++) {
      if (this.snapToOptions[i].id === snapTo) {
        return this.snapToOptions[i].description || ''
      }
    }
    return ''
  }
}
